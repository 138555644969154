.email-capture {
  // background-color: lighten($color-secondary, 23);
  background-color: lighten($color-primary-light, 50);
  width: 100%;

  padding: 100px 0;
  // margin: 50px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  &-container {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 80%;
    max-width: 1000px;
    padding: 64px 20px 114px 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 2px 3px 5px rgba(0,0,0,.1);
  }

  &-title {
    color: #363945;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 25px;

    svg {
      width: 40px;
      height: 40px;
    }
  }
  &-subtitle {
    max-width: 675px;
    line-height: 1.5;
    text-align: center;
    font-size: 21px;
    margin-bottom: 30px;
  }

  &-input-fields {
    width: 100%;
    max-width: 675px;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      width: 45%;
      padding: 10px 10px;
      font-size: 16px;
      border-radius: 3px;
      border: 1px solid hsl(0, 0%, 80%);

      &:first-of-type {
        margin-right: 5px;
      }
    }
  }

  &-button {
    position: absolute;
    bottom: 0;
    height: 60px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    border-radius: 0 0 5px 5px;
    // background-color: darken($color-secondary, 8);
    background-color: $color-primary-light;

    font-size: 18px;
    font-weight: 500;

    &:hover {
      background-color: darken($color-primary-light, 3);
    }
  }

  .icon-mail {
    width: 20px;
    height: 20px;
    fill: #fff;
    margin-right: 5px;

    opacity: 0;
    visibility: hidden;
    transform: rotate(0deg);
    transition: all .2s ease;
  }
  &-button:hover .icon-mail {
    opacity: 1;
    visibility: visible;
    transform: rotate(-4deg) scale(1.12);
  }
}