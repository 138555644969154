@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700&display=swap');

// Font variables
$font-raleway: 'Raleway', sans-serif;

// Color Variables
$color-primary: #2e4b37;
$color-primary-light: lighten($color-primary, 20);

$color-secondary: #b2b2a2;
$color-tertiary: #363636;

$color-nav: rgb(54, 57, 69);
$color-test: orange;

// Media query breakpoint
$desktop: 1200px;
$tablet: 900px;
$mobile: 768px;