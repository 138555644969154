.Login {
  position: relative;
  width: 100vw;
  height: 100vh;
  color: $color-nav;
  background-color: $color-primary-light;

  &-body {
    width: 70%;
    max-width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;

    padding: 64px 32px;
    color: #222;
    background-color: white;
    border-radius: 5px;

    @media screen and (max-width: $tablet) {
      width: 90%;

      h1 {
        font-size: 24px;
      }
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 32px;
  }

  input {
    width: 400px;
    padding: 8px 12px;
    margin: 4px 0;
    border-radius: 4px;
    font-size: 16px;

    @media screen and (max-width: $tablet) {
      width: 95%;
    }
  }
  input[type="submit"] {
    cursor: pointer;
  }
}