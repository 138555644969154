.AdminDashboard-sidebar {
  margin: 3rem 0;
}

@media (max-width: 1020px) {
  .AdminDashboard-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}