.Footer {
  width: 100%;
  color: #333;
  background-color: #f4f4f4;

  &-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 24px 0;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media screen and (max-width: $tablet) {
      flex-direction: column;
      padding-bottom: 50px;
    }
  }

  &-container > * {
    width: 30%;

    @media screen and (max-width: $tablet) {
      width: 80%;
    }
  }

  &-left {
    height: 165px;

    @media screen and (max-width: $tablet) {
      height: auto;
      width: 200px;
    }
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-mid {
    text-align: center;

    @media screen and (max-width: $tablet) {
      margin-bottom: 44px;
    }

    h3 {
      font-size: 30px;
      margin-bottom: 8px;
    }
  }

  &-right {
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      font-size: 24px;
      margin-bottom: 4px;
    }
    a {
      margin: 4px 0;

      @media screen and (max-width: $tablet) {
        font-size: 18px;
      }
    }
  }
}