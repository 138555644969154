.ListingsEdit {
  .edit-images-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .add-new-container {
      width: 70%;
      padding: .4rem 0;
      border-radius: 3px;
      cursor: pointer;
      text-align: center;
      font-size: 1.2rem;

      p {
        text-align: center;
      }

      &:hover {
        background-color: rgba(0,0,0,.05);
      }
    }

    .image-card-container {
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;
  
      .image-card {
        width: 23%;
        position: relative;
        margin: 0 .2rem;

        img, iframe {
          overflow: hidden;
          border-radius: 4px;
        }
    
        .buttons {
          position: absolute;
          top: 5px;
          right: 5px;
  
          .button {
            margin: 0;
            margin-left: .2rem;
          }
        }
      }
    }

    .btn-add-new {
      margin-top: 2rem;
    }
  }

  .modal {
    .modal-content {
      font-size: 1.2rem;
      padding: 3rem 4rem;
      // font-family: $font-montserrat;
      background-color: #eee;

      p {
        margin-bottom: 1.1rem;
      }
      .button {
        padding: 1rem 1.7rem;
        margin: 1rem .4rem;
      }
    }
  }
}