.listings {
  padding: 0 48px;
  padding-bottom: 80px;

  @media screen and (max-width: $tablet) {
    padding: 0 24px;
  }
  
  h2 {
    margin: 60px auto;
    // margin-bottom: 24px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;

    @media (max-width: $mobile) {
      font-size: 20px;
      padding: 0 12px;
    }
  }

  &-gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
    // padding: 0 48px;

    a {
      text-decoration: none;
      display: block;
    }
  }
}

.listings-sort-by {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 22px;
  margin-bottom: 16px;

  select {
    margin-left: 12px;
  }
}