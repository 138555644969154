.highlights {
  h2 {
    margin: 60px auto;
    text-align: center;
    font-size: 24px;
    font-weight: 600;

    @media screen and (max-width: $tablet) {
      font-size: 24px;
      max-width: 80%;
    }
  }

  &-panels {
    margin: 0 auto;
    max-width: 1200px;

    display: flex;
    align-items: center;

    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }
  }

  .panel {
    position: relative;
    display: flex;
    flex-direction: column;

    height: 400px;
    width: 33%;
    padding: 40px;
    border: 1px solid #efefef;

    @media screen and (max-width: $tablet) {
      width: 85%;
    }

    &:first-of-type {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px; 
    }
    &:last-of-type {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:first-of-type .panel-icon {
      background-color: rgba($color-primary-light, .2);
      .primary { fill: $color-primary-light; }
    }
    &:nth-of-type(2) .panel-icon {
      background-color: rgba($color-secondary, .2);
      .primary { fill: $color-secondary; }
    }
    &:last-of-type .panel-icon {
      background-color: rgba($color-tertiary, .2);
      .primary { fill: $color-tertiary; }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 999px;
      .secondary { fill: #fff; }

      svg {
        width: 45px;
        height: 45px;
      }
    }

    &-text {
      transform: translateY(60px);
      transition: transform .15s ease-in-out 0s;

      h3 {
        margin: 18px 0;
        font-size: 30px;
        font-weight: 500;
        line-height: 1.2;
      }
      p {
        font-size: 20px;
        line-height: 1.2;
        opacity: 0;
        visibility: hidden;
      }
    }

    &:active {
      .panel-chevron { display: none; }
    }

    &-chevron {
      align-self: center;
      position: absolute;
      bottom: -10px;

      svg {
        width: 70px;
        height: 70px;
        fill: #D6DADE;
      }
    }

    // panel hover styles
    &:hover {
      cursor: pointer;
      color: #fff;
      .primary { fill: #fff;}
    }
    &:first-of-type:hover {
      background-color: $color-primary-light;
      .primary { fill: #fff;}
      .secondary { fill: $color-primary-light;}
    }
    &:nth-of-type(2):hover {
      background-color: $color-secondary;
      .primary { fill: #fff; };
      .secondary { fill: $color-secondary; };
    }
    &:last-of-type:hover {
      background-color: $color-tertiary;
      .primary { fill: #fff;}
      .secondary { fill: $color-tertiary;}
    }

    &:hover .panel-icon {
      background-color: rgba(#fff, .2);
    }
    &:hover .panel-chevron svg {
      fill: #fff;
    }
    &:hover .panel-text {
      transform: translateY(0px);

      p {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}