.banner {
  background-color: rgb(31, 41, 55);
  height: 64px;

  &-text {
    width: 100%;
    height: 100%;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;

    button {
      color: white;
      margin-left: 8px;
      font-weight: 700;
      text-decoration: underline;
    }
  }

  &-modal {
    position: absolute;
    z-index: 20;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;   
    width: 98vw;
    max-width: 1024px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 4px 8px 5px rgba(0,0,0,.2);
    border: 1px solid rgba(0,0,0,.2);

    &-close {      
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 30;

      svg {
        width: 40px;
        height: 40px;
        fill: black;
        &:hover {
          fill: rgba(0,0,0,.6);
        }
      }
    }

    &-content {      
      display:  flex;
      position: relative;
      flex-direction: row;  
      
      @media screen and (max-width: $tablet) {
        flex-direction: column-reverse; 
      }
    }

    &-text {
      width: 50%;
      padding: 40px 40px;

      @media screen and (max-width: $tablet) {
        width: 100%; 
      }

      h2 {
        font-size: 30px;
        line-height: 32px;
      }
      h4 {
        font-size: 24px;
        font-weight: 500;
        margin-top: 4px;
        margin-bottom: 12px;
      }
      p {
        font-size: 18px;
        line-height: 28px;
        color: gray;
      }
    }

    &-image {
      width: 50%;
      overflow: hidden;            

      @media screen and (max-width: $tablet) {
        width: 100%; 
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}