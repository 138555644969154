.testimonials {
  margin: 50px 0;
  padding-bottom: 80px;

  [class$='header'] {
    margin: 60px auto;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  &-gallery {
    position: relative;
    // width: 100%;
  }

  [class^='testimonials-gallery-nav'] {
    position: absolute;
    top: 50%;

    cursor: pointer;
    background-color: transparent;
    border: none;

    &:hover {
      .primary {
        fill: hsl(0, 0%, 93%);
      }
    }
    &:focus {
      outline: none;
    }

    .primary {
      fill: hsl(0, 0%, 97%);
      transition: all .2s ease;
    }
    .secondary {
      fill: $color-primary-light;
    }

    svg {
      width: 60px;
      height: 60px;
    }
  }
  .testimonials-gallery-nav-left {
    left: 10px;

    @media screen and (max-width: $tablet) {
      left: 0;
    }
  }
  .testimonials-gallery-nav-right {
    right: 10px;

    @media screen and (max-width: $tablet) {
      right: 0;
    }
  }

  &-card {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding: 50px;
    width: 80%;
    max-width: 1000px;
    min-height: 300px;

    border: 1px solid #d6dade;
    border-radius: 6px;
    box-shadow: 20px 20px $color-primary-light;

    @media screen and (max-width: $tablet) {
      padding: 0;
      box-shadow: 8px 8px $color-primary-light;
    }

    p {
      margin: 30px 0;
      font-size: 28px;
      line-height: 40px;

      @media screen and (max-width: $tablet) {
        padding: 24px;
        padding-bottom: 4px;
        margin: 10px 0;
        font-size: 16px;
        line-height: 24px;
      }
    }

    span {
      color: hsl(0, 0%, 35%);
      font-size: 45px;
      font-family: serif;

      @media screen and (max-width: $tablet) {
        font-size: 30px;
      }
    }

    &-info {
      margin: 0 auto;

      display: flex;
      align-items: center;

      @media screen and (max-width: $tablet) {
        flex-direction: column;
        // align-items: center;
      }

      &-image {
        height: 70px;
        width: 70px;
        object-fit: cover;
        border-radius: 50%;
        border: 3px solid $color-primary-light;
      }

      &-text-content {
        margin-left: 20px;

        @media screen and (max-width: $tablet) {
          display: flex;
          margin-left: 0;
          flex-direction: column;
          align-items: center;
        } 
      }

      &-name {
        color: #505050;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 10px;

        @media screen and (max-width: $tablet) {
          font-size: 20px;
          margin: 4px 0 0 0;
        }
      }
      &-location {
        color: #b2b9c1;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;

        @media screen and (max-width: $tablet) {
          font-size: 16px;
          margin-bottom: 12px;
        }
      }
    }
  }

  // React Transition Group Styles
  .testimonial-enter {
    opacity: 0;
  }
  .testimonial-enter-active {
    opacity: 1;
  }
  .testimonial-exit {
    opacity: 1;
  }
  .testimonial-exit-active {
    opacity: 0;
  }
}

@keyframes fadeInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}