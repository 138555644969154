.navbar {
  width: 100%;
  min-height: 60px;
  padding: 0 64px;

  border-bottom: 1px solid hsl(0, 0%, 95%);
  background-color: hsl(0, 0%, 100%);
  border-bottom: px solid #EFEFEF;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $tablet) {
    padding: 0 24px;
  }

  a {
    margin: 23px 15px;
    color: $color-nav;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
  }
  a:hover {
    color: $color-primary-light;
  }

  &-brand {
    svg {
      width: 24px;
      height: 24px;
      fill: $color-nav;

      &:hover {
        fill: $color-primary-light;
      }
    }
  }

  &-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-menu-items {
    display: flex;
    align-items: center;
    z-index: 10;

    @media screen and (max-width: $tablet) {
      display: none;
    }
  }
  &-menu-items.is-active {
    @media screen and (max-width: $tablet) {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 60px;
      width: 100%;
      padding-left: 24px;
      background-color: #fff;
      border-bottom: 1px solid #ddd;
    }
  }

  &-burger-icon {
    display: none;

    @media screen and (max-width: $tablet) {
      display: inline;
    }

    svg {
      width: 24px;
      height: 24px;
      fill: $color-nav;
      cursor: pointer;

      &:hover {
        fill: $color-primary-light;
      }
    }
  }

  &-ctas {
    @media screen and (max-width: $tablet) {
      display: none;
    }

    a {
      color: #fff;
      background-color: $color-primary-light;
      border-bottom: 3px solid lighten($color-primary, 10);
      
      padding: 10px 32px;
      border-radius: 5px;

      &:hover {
        color: #fff;
        background-image: linear-gradient(to top right, lighten($color-primary, 25), $color-primary-light);
      }
    }
  }
}