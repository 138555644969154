.listing-card {
  width: 300px;
  // height: 320px;
  display: block;
  overflow: hidden;
  margin: 22px 22px;

  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.15);
  transition: all .2s ease .05s;

  &:hover {
    box-shadow: 0 0 12px 0 rgba(0,0,0,.35);
    // transform: translateY(-4px);
  }
  &:hover img {
    transform: scale(1.08);
  }

  @media (max-width: $tablet) {
    width: 40%;
    max-width: 300px;
    margin: 24px 22px;
  }
  @media (max-width: $mobile) {
    width: 80%;
    max-width: 300px;
    margin: 24px 22px;
  }

  &-image-gallery {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  &-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .2s ease .05s;
  }

  &-content {
    padding: 12px;
    color: $color-nav;
    font-weight: 500;

    & > * {
      line-height: 1.5;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    // justify-content: space-between;

    font-size: 22px;
    font-weight: 600;
    
    span {
      color: rgba($color-nav, .8);
      font-size: 16px;
      font-weight: 500;
    }
  }
  &-subtitle {
    display: flex;
    align-items: center;
  }
  &-description {}

  [class^='icon'] {
    width: 18px;
    height: 18px;
  }
  .primary, .secondary {
    fill: rgba($color-nav, .8);
  }
}