.about {
  height: 100%;
  min-height: 100vh;
  width: 100%;

  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10vw 2vw 9.5vw;

    color: #fff;
    background-image: linear-gradient(rgba(0,0,0,.75), rgba(0,0,0,.4)), url('../../assets/images/about-us-header.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: $tablet) {
      padding: 0 28px 42px 28px;
    }

    h1 {
      max-width: 900px;
      margin-bottom: 20px;
      font-size: 50px;
      font-weight: 600;
      line-height: 1.3;

      @media screen and (max-width: $tablet) {
        font-size: 38px;
        margin-top: 24px;
      }
    }
    p {
      max-width: 780px;
      font-size: 24px;
      line-height: 1.45;
      
      @media screen and (max-width: $tablet) {
        font-size: 16px;
      }
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;
    padding: 10vw 2vw 9.5vw;
    background-color: lighten($color-primary-light, 50);

    h2 {
      color: #363945;
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 20px;

      @media screen and (max-width: $tablet) {
        font-size: 38px;
      }
    }
    img {
      width: 400px;
      margin: 16px 0;
      filter: sepia(.15);
      border-radius: 8px;
      // box-shadow: 3px 5px 5px rgba(0,0,0,.1);
      box-shadow: 0 0 5px 2px rgba(0,0,0,.1);

      @media screen and (max-width: $tablet) {
        width: 80%;
      }
    }
    p {
      max-width: 600px;
      margin-top: 20px;
      color: #5b6b7f;
      font-size: 20px;
      line-height: 1.5;

      @media screen and (max-width: $tablet) {
        font-size: 18px;
        padding: 12px 20px 0 20px;
      }
    }
  }
}