@import './styles/bulma.scss';
@import './styles/variables.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-raleway;
}

body {
  width: 100vw;
  min-height: 100vh;
  overscroll-behavior: none;
  overflow-x: hidden !important;
}

a, a:visited, a:active {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  
  &:focus {
    outline: none;
  }
}

.root {
  width: 100%;
}

.App {
  width: 100%;
}

@import './components/coming-soon/ComingSoon.scss';

@import './components/navbar/Navbar.scss';

@import './components/home/hero/Hero.scss';
@import './components/home/highlights/Highlights.scss';
@import './components/home/testimonials/Testimonials.scss';
@import './components/home/email-capture/EmailCapture.scss';

@import './components//listings/Listings.scss';
@import './components//listings//listingCard/ListingCard.scss';
@import './components/listings/listingDetails/ListingDetails.scss';

@import './components/about/About.scss';

@import './components/admin/login/Login.scss';

@import './components/footer/Footer.scss';

@import './components/banner/Banner.scss';